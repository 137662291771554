'use client';

import { Button, Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { HiOutlineExclamationCircle } from "react-icons/hi";
export default function Dialog({show,onYes,onNo}) {
  const [openModal, setOpenModal] = useState();
  const props = { openModal, setOpenModal };

  return (
    <>
      <Modal show={show} size="md" popup onClose={onNo}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
             Before you Proceed, Kindly look through again and confirm that you have accurately entered your details
            </h3>
            <div className="flex justify-center gap-4">
              <Button color='default'  onClick={onYes} className='bg-[#60269e] hover:bg-[#602690] text-white'>
                Yes, proceed
              </Button>
              <Button color="gray" onClick={onNo}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}


