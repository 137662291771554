import React from "react";

export  function InputField(props){
    const {name,label} = props
    return(
        <div>
         <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
         <input type="number" name={name}  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" {...props}/>
        </div>
    )
}

export  function UploadField(props){
    const {name,label} = props
    return(
        <div>
         <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">{label}</label>
         <input name={name} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" {...props}/>
        </div>
    )
}