import './App.css';
import {InputField, UploadField} from "./components/input"
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Loading } from './components/icon';
import Dialog from './components/modal';
function App() {
  const [isLoading,setLoading] = useState(false)
  const [dialog,setDialog] = useState(false)
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    code: "",
    kyc: "",
    picture: ""
  });

 
  const handleSubmit = async(e) => {
    e.preventDefault();
   setDialog(true)
  };

  const submitToServer = async(event)=>{
    console.log(data)
    setDialog(false)
    setLoading(true)
    event.preventDefault();
    let formData = new FormData()
    formData.append("firstname",data.firstname)
    formData.append("lastname",data.lastname)
    formData.append("phone",data.phone)
    formData.append("email",data.email)
    formData.append("code",data.code)
    formData.append("kyc",data.kyc)
    formData.append("picture",data.picture)
    
    await axios.post('https://container-service-1.c6m8rrssrut3k.eu-central-1.cs.amazonlightsail.com/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      })
        .then(function (response) {
           toast("Funds request submitted");
           setData({
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            code: "",
            kyc: "",
            picture: ""
           })
           setTimeout(function(){
            window.location.reload();
         }, 3000);
        })
        .catch(function (error) {
          toast("Request failed, please try again later");
          console.log(error);
        });
        setLoading(false)
  }
  const handleChange=(event)=>{
    setData({...data,[event.target.name]: event.target.value})
   };

   const handleChangeUpload=(event)=>{
    setData({...data,[event.target.name]: event.target.files[0]})
   };

  return (
    <section class="bg-gray-50 dark:bg-gray-900">
      <Dialog show={dialog} onYes={submitToServer} onNo={()=>setDialog(false)}/>
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
      <img class="w-32 h-32 -mb-8" src="https://bundle-static-contents.s3.amazonaws.com/images/Bundle-logo.svg" alt="logo"/>
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-2xl xl:p-0  dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Request funds
              </h1>
              <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit} action='#'>
                
                  <div className='flex flex-col lg:flex-row  justify-between'> 
                    <div className='w-full lg:w-[48%]  mb-4 lg:mb-0'>
                        <InputField type="text" name="firstname"  placeholder="Garba" label="Your Firstname" onChange={handleChange} value={data.firstname} required/>
                    </div>
                    <div className='w-full lg:w-[48%]'>
                        <InputField type="text" name="lastname"  placeholder="Shehu" label="Your Lastname" onChange={handleChange} value={data.lastname} required/>
                    </div>
                  </div>
                  
                  <InputField type="email" name="email"  placeholder="test@example.com" label="Your email" onChange={handleChange} value={data.email} required/>
                  
                   <InputField type="text" name="phone"  placeholder="+2348065656576" label="Phone Number e.g +2348065656576" onChange={handleChange} value={data.phone} required/>
                  
                  <InputField type="text" name="code"  placeholder="••••••••" label="Claim Code" onChange={handleChange} value={data.code} required/>
                  <InputField type="text" name="kyc"  placeholder="22284345444" label="BVN/NIN/Passport/Driver's Licence" onChange={handleChange} value={data.kyc} required/>
                  {/* <UploadField  name="passport" label="Passport" aria-describedby="file_input_help" onChange={handleChangeUpload}  id="file_input" type="file" required/> */}
                  <UploadField  name="picture" label="Picture: (a photo of you holding your document which you used for registration + paper showing clearly 1~5 above handwritten info and today's date. Note: Face and written info must show clearly)" aria-describedby="file_input_help" id="file_input" type="file" onChange={handleChangeUpload}  required/>
                 <br/>
                  {/* <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required/>
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="terms" class="font-light text-gray-500 dark:text-gray-300">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                      </div>
                  </div> */}
                  <button disabled={isLoading}  class="w-full justify-center items-center flex text-white bg-[#60269e] hover:bg-[#602690] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-4 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                    {isLoading ? <Loading/> : "Submit"}
                  </button>
              </form>
          </div>
      </div>
  </div>
</section>
  );
}

export default App;
